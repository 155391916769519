<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <v-card>
          <v-tabs
            v-model="tab"
            class="mb-9"
            grow
          >
            <v-tab @click="fetchBrokers()">Brokers</v-tab>
            <v-tab @click="fetchCorporates()">Corporates</v-tab>
            <v-tab @click="fetchNiagamasLoyalties()">Loyalty</v-tab>
            <v-tab @click="fetchNiagamasMerchants()">Merchant</v-tab>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>
    <v-tabs-items
      id="tab-items"
      v-model="tab"
    >
      <v-tab-item>
        <v-card id="broker-list">
          <v-card-text class="d-flex align-center flex-wrap pb-0">
            <div
              v-if="can('write', 'ProfileCreation')"
              class="d-flex align-center pb-6"
            >
              <!-- create receipt -->
              <v-btn
                color="primary"
                class="me-3 receipt-button"
                :to="{ name: 'apps-profile-creation-broker-add' }"
              >
                <v-icon
                  size="18"
                  class="me-1"
                >
                  {{ icons.mdiPlus }}
                </v-icon>
                <span>{{ t('Create Broker') }}</span>
              </v-btn>
            </div>

            <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>

            <div class="row text-right pb-6">
              <div class="d-flex align-center col-sm-5 offset-sm-4 col-12">
                <!-- search broker -->
                <v-tooltip
                  :left="$vuetify.breakpoint.smAndUp"
                  :top="$vuetify.breakpoint.xsOnly"
                  :nudge-bottom="$vuetify.breakpoint.smAndUp ? 20 : 0"
                  :nudge-top="$vuetify.breakpoint.xsOnly ? 5 : 0"
                  :nudge-right="$vuetify.breakpoint.xsOnly ? 100 : 0"
                  max-width="250"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-avatar
                      size="30"
                      class="mr-3 hover-pointer"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon size="25">
                        {{ icons.mdiAlertCircleOutline }}
                      </v-icon>
                    </v-avatar>
                  </template>
                  <h3 class="my-2">
                    {{ t('How to use the search bar') }}
                  </h3>
                  <p class="mb-1">
                    {{ t('You can search using') }}:
                  </p>
                  <ul class="mb-2">
                    <li>
                      {{ t('Record No.') }}
                    </li>
                    <li>
                      {{ t('Name') }}
                    </li>
                    <li>
                      {{ t('IC No.') }}
                    </li>
                    <li>
                      {{ t('Contact No.') }}
                    </li>
                  </ul>
                </v-tooltip>
                <v-text-field
                  v-model="brokerSearchText"
                  :append-icon="icons.mdiMagnify"
                  single-line
                  dense
                  outlined
                  hide-details
                  :placeholder="t('Search Broker')"
                  :class="{ 'broker-list-search': $vuetify.breakpoint.mdAndUp }"
                ></v-text-field>
              </div>

              <div class="col-sm-3 col-12">
                <v-btn
                  block
                  color="primary"
                  @click="searchBrokers"
                >
                  <v-icon
                    class="pr-2"
                    size="30"
                  >
                    {{ icons.mdiMagnify }}
                  </v-icon>
                  Search
                </v-btn>
              </div>
            </div>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-text class="pt-5">
            <v-row>
              <v-col
                lg="2"
                cols="3"
              >
                <div class="d-flex align-center">
                  <v-select
                    v-model="brokerTablePagination.per"
                    :items="brokerListLengthOptions"
                    single-line
                    outlined
                    dense
                    hide-details
                    class="profile-list-row-selection"
                    @input="brokerListLengthChange()"
                  ></v-select>
                  <div class="pl-3">
                    {{ t('Rows') }}
                  </div>
                </div>
              </v-col>

              <v-col
                lg="10"
                cols="9"
                class="d-flex justify-end"
              >
                <v-pagination
                  v-model="brokerTablePagination.current_page"
                  total-visible="6"
                  :length="brokerTablePagination.total_page"
                  class="list-row-selection"
                  @input="brokerPageChange()"
                ></v-pagination>
              </v-col>
            </v-row>
          </v-card-text>

          <!-- table -->
          <v-data-table
            :headers="brokerTableColumns"
            :items="brokers"
            :items-per-page="100"
            :loading="loading"
            mobile-breakpoint="0"
            hide-default-footer
            :disable-sort="true"
            class="text-no-wrap"
          >
            <!-- Actions -->
            <template #[`item.actions`]="{item}">
              <div class="d-flex align-center justify-center">
                <!-- Send SMS -->
                <v-tooltip
                  v-if="isAuditor"
                  bottom
                >
                  <template #activator="{ on, attrs }">
                    <div
                      v-on="on"
                      v-bind="attrs"
                      class="d-inline-block"
                    >
                      <v-btn
                        icon
                        small
                        @click="isSendSmsDialogVisible = true; brokerSms = item"
                        :disabled="item.sms_sent_at !== 'NULL'"
                      >
                        <v-icon size="18">
                          {{ icons.mdiMessageProcessingOutline }}
                        </v-icon>
                      </v-btn>
                    </div>
                  </template>
                  <span>
                    {{ item.sms_sent_at === 'NULL' ? 'Send SMS' : `SMS already sent at ${item.sms_sent_at}` }}
                  </span>
                </v-tooltip>

                <!-- Edit -->
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      :to="{
                        name: (can('write', 'ReportActions') ? 'apps-profile-creation-broker-edit' : 'apps-profile-creation-broker-req'),
                        params: { id: item.id }
                      }"
                      icon
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon size="18">
                        {{ icons.mdiPencilOutline }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>

                <!-- Void -->
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      icon
                      small
                      v-bind="attrs"
                      v-on="on"
                      @click="voidableRecord = item; isVoidDialogVisible = true"
                    >
                      <v-icon size="18">
                        {{ icons.mdiDeleteOutline }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Void</span>
                </v-tooltip>

                <!-- View IC -->
                <v-tooltip
                  v-if="item.ic_url !== 'NULL'"
                  bottom
                >
                  <template #activator="{ on, attrs }">
                    <v-btn
                      icon
                      small
                      :href="item.ic_url"
                      target="_blank"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon size="18">
                        {{ icons.mdiFileDocumentOutline }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>View IC pdf</span>
                </v-tooltip>
                <v-tooltip
                  v-else
                  bottom
                >
                  <template #activator="{ on }">
                    <div v-on="on">
                      <v-btn
                        icon
                        small
                        disabled
                      >
                        <v-icon size="18">
                          {{ icons.mdiHelpCircleOutline }}
                        </v-icon>
                      </v-btn>
                    </div>
                  </template>
                  <span>No IC attached</span>
                </v-tooltip>
              </div>
            </template>

            <!-- Record No. -->
            <template #[`item.id`]="{item}">
              BKR-{{ item.id }}
            </template>
          </v-data-table>

          <v-card-text class="pt-5">
            <v-row>
              <v-col
                lg="2"
                cols="3"
              >
                <div class="d-flex align-center">
                  <v-select
                    v-model="brokerTablePagination.per"
                    :items="brokerListLengthOptions"
                    single-line
                    outlined
                    dense
                    hide-details
                    class="profile-list-row-selection"
                    @input="brokerListLengthChange()"
                  ></v-select>
                  <div class="pl-3">
                    {{ t('Rows') }}
                  </div>
                </div>
              </v-col>

              <v-col
                lg="10"
                cols="9"
                class="d-flex justify-end"
              >
                <v-pagination
                  v-model="brokerTablePagination.current_page"
                  total-visible="6"
                  :length="brokerTablePagination.total_page"
                  class="list-row-selection"
                  @input="brokerPageChange()"
                ></v-pagination>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card id="corporate-list">
          <v-card-text class="d-flex align-center flex-wrap pb-0">
            <div
              v-if="can('write', 'ProfileCreation')"
              class="d-flex align-center pb-6"
            >
              <!-- create receipt -->
              <v-btn
                color="primary"
                class="me-3 receipt-button"
                :to="{ name: 'apps-profile-creation-corporate-add' }"
              >
                <v-icon
                  size="18"
                  class="me-1"
                >
                  {{ icons.mdiPlus }}
                </v-icon>
                <span>{{ t('Create Corporate') }}</span>
              </v-btn>
            </div>

            <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>

            <div class="row text-right pb-6">
              <div class="d-flex align-center col-sm-5 offset-sm-4 col-12">
                <!-- search corporate -->
                <v-tooltip
                  :left="$vuetify.breakpoint.smAndUp"
                  :top="$vuetify.breakpoint.xsOnly"
                  :nudge-bottom="$vuetify.breakpoint.smAndUp ? 20 : 0"
                  :nudge-top="$vuetify.breakpoint.xsOnly ? 5 : 0"
                  :nudge-right="$vuetify.breakpoint.xsOnly ? 100 : 0"
                  max-width="250"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-avatar
                      size="30"
                      class="mr-3 hover-pointer"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon size="25">
                        {{ icons.mdiAlertCircleOutline }}
                      </v-icon>
                    </v-avatar>
                  </template>
                  <h3 class="my-2">
                    {{ t('How to use the search bar') }}
                  </h3>
                  <p class="mb-1">
                    {{ t('You can search using') }}:
                  </p>
                  <ul class="mb-2">
                    <li>
                      {{ t('Record No.') }}
                    </li>
                    <li>
                      {{ t('Name') }}
                    </li>
                    <li>
                      {{ t('ROC No.') }}
                    </li>
                    <li>
                      {{ t('Contact Person No.') }}
                    </li>
                  </ul>
                </v-tooltip>
                <v-text-field
                  v-model="corporateSearchText"
                  :append-icon="icons.mdiMagnify"
                  single-line
                  dense
                  outlined
                  hide-details
                  :placeholder="t('Search Corporate')"
                  :class="{ 'corporate-list-search': $vuetify.breakpoint.mdAndUp }"
                ></v-text-field>
              </div>

              <div class="col-sm-3 col-12">
                <v-btn
                  block
                  color="primary"
                  @click="searchCorporates"
                >
                  <v-icon
                    class="pr-2"
                    size="30"
                  >
                    {{ icons.mdiMagnify }}
                  </v-icon>
                  Search
                </v-btn>
              </div>
            </div>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-text class="pt-5">
            <v-row>
              <v-col
                lg="2"
                cols="3"
              >
                <div class="d-flex align-center">
                  <v-select
                    v-model="corporateTablePagination.per"
                    :items="corporateListLengthOptions"
                    single-line
                    outlined
                    dense
                    hide-details
                    class="profile-list-row-selection"
                    @input="corporateListLengthChange()"
                  ></v-select>
                  <div class="pl-3">
                    {{ t('Rows') }}
                  </div>
                </div>
              </v-col>

              <v-col
                lg="10"
                cols="9"
                class="d-flex justify-end"
              >
                <v-pagination
                  v-model="corporateTablePagination.current_page"
                  total-visible="6"
                  :length="corporateTablePagination.total_page"
                  @input="corporatePageChange()"
                ></v-pagination>
              </v-col>
            </v-row>
          </v-card-text>

          <!-- table -->
          <v-data-table
            :headers="corporateTableColumns"
            :items="corporates"
            :items-per-page="100"
            :loading="loading"
            mobile-breakpoint="0"
            hide-default-footer
            :disable-sort="true"
            class="text-no-wrap"
          >
            <!-- Actions -->
            <template #[`item.actions`]="{item}">
              <div class="d-flex align-center justify-center">
                <!-- Edit -->
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      :to="{
                        name: (can('write', 'ReportActions') ? 'apps-profile-creation-corporate-edit' : 'apps-profile-creation-corporate-req'),
                        params: { id: item.id }
                      }"
                      icon
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon size="18">
                        {{ icons.mdiPencilOutline }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>

                <!-- Void -->
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      icon
                      small
                      v-bind="attrs"
                      v-on="on"
                      @click="voidableRecord = item; isVoidDialogVisible = true"
                    >
                      <v-icon size="18">
                        {{ icons.mdiDeleteOutline }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Void</span>
                </v-tooltip>

                <!-- View Company 16/17 -->
                <v-tooltip
                  v-if="item.cert_url !== 'NULL'"
                  bottom
                >
                  <template #activator="{ on, attrs }">
                    <v-btn
                      icon
                      small
                      :href="item.cert_url"
                      target="_blank"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon size="18">
                        {{ icons.mdiFileDocumentOutline }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>View company 16/17</span>
                </v-tooltip>
                <v-tooltip
                  v-else
                  bottom
                >
                  <template #activator="{ on }">
                    <div v-on="on">
                      <v-btn
                        icon
                        small
                        disabled
                      >
                        <v-icon size="18">
                          {{ icons.mdiHelpCircleOutline }}
                        </v-icon>
                      </v-btn>
                    </div>
                  </template>
                  <span>No 16/17 attached</span>
                </v-tooltip>
              </div>
            </template>

            <!-- Record No. -->
            <template #[`item.id`]="{item}">
              COR-{{ item.id }}
            </template>
          </v-data-table>

          <v-card-text class="pt-5">
            <v-row>
              <v-col
                lg="2"
                cols="3"
              >
                <div class="d-flex align-center">
                  <v-select
                    v-model="corporateTablePagination.per"
                    :items="corporateListLengthOptions"
                    single-line
                    outlined
                    dense
                    hide-details
                    class="profile-list-row-selection"
                    @input="corporateListLengthChange()"
                  ></v-select>
                  <div class="pl-3">
                    {{ t('Rows') }}
                  </div>
                </div>
              </v-col>

              <v-col
                lg="10"
                cols="9"
                class="d-flex justify-end"
              >
                <v-pagination
                  v-model="corporateTablePagination.current_page"
                  total-visible="6"
                  :length="corporateTablePagination.total_page"
                  @input="corporatePageChange()"
                ></v-pagination>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card id="loyalty-list">
          <v-card-text class="d-flex align-center flex-wrap pb-0">
            <div
              v-if="can('write', 'ProfileCreation')"
              class="d-flex align-center pb-6"
            >
              <!-- create receipt -->
              <v-btn
                color="primary"
                class="me-3 receipt-button"
                :to="{ name: 'apps-profile-creation-loyalty-add' }"
              >
                <v-icon
                  size="18"
                  class="me-1"
                >
                  {{ icons.mdiPlus }}
                </v-icon>
                <span>{{ t('Create Niagamas Loyalty') }}</span>
              </v-btn>
            </div>

            <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>

            <div class="row text-right pb-6">
              <div class="col-sm-4 col-12">
                <!-- filter status -->
                <v-select
                  v-model="statusFilter"
                  :items="statusOptions"
                  single-line
                  outlined
                  dense
                  hide-details
                  clearable
                  :placeholder="t('Select Status')"
                  :class="{ 'loyalty-list-status': $vuetify.breakpoint.smAndUp }"
                ></v-select>
              </div>

              <div class="d-flex align-center col-sm-5 col-12">
                <!-- search loyalty -->
                <v-tooltip
                  :left="$vuetify.breakpoint.smAndUp"
                  :top="$vuetify.breakpoint.xsOnly"
                  :nudge-bottom="$vuetify.breakpoint.smAndUp ? 20 : 0"
                  :nudge-top="$vuetify.breakpoint.xsOnly ? 5 : 0"
                  :nudge-right="$vuetify.breakpoint.xsOnly ? 100 : 0"
                  max-width="250"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-avatar
                      size="30"
                      class="mr-3 hover-pointer"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon size="25">
                        {{ icons.mdiAlertCircleOutline }}
                      </v-icon>
                    </v-avatar>
                  </template>
                  <h3 class="my-2">
                    {{ t('How to use the search bar') }}
                  </h3>
                  <p class="mb-1">
                    {{ t('You can search using') }}:
                  </p>
                  <ul class="mb-2">
                    <li>
                      {{ t('Record No.') }}
                    </li>
                    <li>
                      {{ t('Name') }}
                    </li>
                    <li>
                      {{ t('IC No.') }}
                    </li>
                    <li>
                      {{ t('Contact No.') }}
                    </li>
                  </ul>
                </v-tooltip>
                <v-text-field
                  v-model="loyaltySearchText"
                  :append-icon="icons.mdiMagnify"
                  single-line
                  dense
                  outlined
                  hide-details
                  :placeholder="t('Search Loyalty')"
                  :class="{ 'loyalty-list-search': $vuetify.breakpoint.mdAndUp }"
                ></v-text-field>
              </div>

              <div class="col-sm-3 col-12">
                <v-btn
                  block
                  color="primary"
                  @click="searchLoyalties"
                >
                  <v-icon
                    class="pr-2"
                    size="30"
                  >
                    {{ icons.mdiMagnify }}
                  </v-icon>
                  Search
                </v-btn>
              </div>
            </div>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-text class="pt-5">
            <v-row>
              <v-col
                lg="2"
                cols="3"
              >
                <div class="d-flex align-center">
                  <v-select
                    v-model="loyaltyTablePagination.per"
                    :items="loyaltyListLengthOptions"
                    single-line
                    outlined
                    dense
                    hide-details
                    class="profile-list-row-selection"
                    @input="loyaltyListLengthChange()"
                  ></v-select>
                  <div class="pl-3">
                    {{ t('Rows') }}
                  </div>
                </div>
              </v-col>

              <v-col
                lg="10"
                cols="9"
                class="d-flex justify-end"
              >
                <v-pagination
                  v-model="loyaltyTablePagination.current_page"
                  total-visible="6"
                  :length="loyaltyTablePagination.total_page"
                  class="list-row-selection"
                  @input="loyaltyPageChange()"
                ></v-pagination>
              </v-col>
            </v-row>
          </v-card-text>

          <!-- table -->
          <v-data-table
            :headers="loyaltyTableColumns"
            :items="loyalties"
            :items-per-page="100"
            :loading="loading"
            mobile-breakpoint="0"
            hide-default-footer
            :disable-sort="true"
            class="text-no-wrap"
          >
            <!-- Actions -->
            <template #[`item.actions`]="{item}">
              <div class="d-flex align-center justify-center">
                <!-- Edit -->
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      :to="{
                        name: (can('write', 'ReportActions') ? 'apps-profile-creation-loyalty-edit' : 'apps-profile-creation-loyalty-req'),
                        params: { id: item.id }
                      }"
                      icon
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon size="18">
                        {{ icons.mdiPencilOutline }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>

                <!-- Void -->
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      icon
                      small
                      v-bind="attrs"
                      v-on="on"
                      @click="voidableRecord = item; isVoidDialogVisible = true"
                    >
                      <v-icon size="18">
                        {{ icons.mdiDeleteOutline }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Void</span>
                </v-tooltip>
              </div>
            </template>

            <!-- Status -->
            <template #[`item.is_expired`]="{item}">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-avatar
                    size="30"
                    :color="(item.is_expired && item.is_expired !== 'NULL') ? 'error' : 'success'"
                    :class="`v-avatar-light-bg ${(item.is_expired && item.is_expired !== 'NULL') ? 'error' : 'success'}--text`"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon
                      size="18"
                      :color="(item.is_expired && item.is_expired !== 'NULL') ? 'error' : 'success'"
                    >
                      {{ (item.is_expired && item.is_expired !== 'NULL') ? icons.mdiAlertCircleOutline : icons.mdiCheckCircleOutline }}
                    </v-icon>
                  </v-avatar>
                </template>
                <span>{{ (item.is_expired && item.is_expired !== 'NULL') ? 'Expired' : 'Active' }}</span>
              </v-tooltip>
            </template>

            <!-- Record No. -->
            <template #[`item.id`]="{item}">
              LOY-{{ item.id }}
            </template>
          </v-data-table>

          <v-card-text class="pt-5">
            <v-row>
              <v-col
                lg="2"
                cols="3"
              >
                <div class="d-flex align-center">
                  <v-select
                    v-model="loyaltyTablePagination.per"
                    :items="loyaltyListLengthOptions"
                    single-line
                    outlined
                    dense
                    hide-details
                    class="profile-list-row-selection"
                    @input="loyaltyListLengthChange()"
                  ></v-select>
                  <div class="pl-3">
                    {{ t('Rows') }}
                  </div>
                </div>
              </v-col>

              <v-col
                lg="10"
                cols="9"
                class="d-flex justify-end"
              >
                <v-pagination
                  v-model="loyaltyTablePagination.current_page"
                  total-visible="6"
                  :length="loyaltyTablePagination.total_page"
                  class="list-row-selection"
                  @input="loyaltyPageChange()"
                ></v-pagination>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card id="merchant-list">
          <v-card-text class="d-flex align-center flex-wrap pb-0">
            <div
              v-if="can('write', 'ProfileCreation')"
              class="d-flex align-center pb-6"
            >
              <!-- create receipt -->
              <v-btn
                color="primary"
                class="me-3 receipt-button"
                :to="{ name: 'apps-profile-creation-merchant-add' }"
              >
                <v-icon
                  size="18"
                  class="me-1"
                >
                  {{ icons.mdiPlus }}
                </v-icon>
                <span>{{ t('Create Niagamas Merchant') }}</span>
              </v-btn>
            </div>

            <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>

            <div class="row text-right pb-6">
              <div class="d-flex align-center col-sm-5 offset-sm-4 col-12">
                <!-- search merchant -->
                <v-tooltip
                  :left="$vuetify.breakpoint.smAndUp"
                  :top="$vuetify.breakpoint.xsOnly"
                  :nudge-bottom="$vuetify.breakpoint.smAndUp ? 20 : 0"
                  :nudge-top="$vuetify.breakpoint.xsOnly ? 5 : 0"
                  :nudge-right="$vuetify.breakpoint.xsOnly ? 100 : 0"
                  max-width="250"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-avatar
                      size="30"
                      class="mr-3 hover-pointer"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon size="25">
                        {{ icons.mdiAlertCircleOutline }}
                      </v-icon>
                    </v-avatar>
                  </template>
                  <h3 class="my-2">
                    {{ t('How to use the search bar') }}
                  </h3>
                  <p class="mb-1">
                    {{ t('You can search using') }}:
                  </p>
                  <ul class="mb-2">
                    <li>
                      {{ t('Record No.') }}
                    </li>
                    <li>
                      {{ t('Name') }}
                    </li>
                    <li>
                      {{ t('ROC No.') }}
                    </li>
                    <li>
                      {{ t('Contact Person No.') }}
                    </li>
                  </ul>
                </v-tooltip>
                <v-text-field
                  v-model="merchantSearchText"
                  :append-icon="icons.mdiMagnify"
                  single-line
                  dense
                  outlined
                  hide-details
                  :placeholder="t('Search Merchant')"
                  :class="{ 'merchant-list-search': $vuetify.breakpoint.mdAndUp }"
                ></v-text-field>
              </div>

              <div class="col-sm-3 col-12">
                <v-btn
                  block
                  color="primary"
                  @click="searchMerchants"
                >
                  <v-icon
                    class="pr-2"
                    size="30"
                  >
                    {{ icons.mdiMagnify }}
                  </v-icon>
                  Search
                </v-btn>
              </div>
            </div>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-text class="pt-5">
            <v-row>
              <v-col
                lg="2"
                cols="3"
              >
                <div class="d-flex align-center">
                  <v-select
                    v-model="merchantTablePagination.per"
                    :items="merchantListLengthOptions"
                    single-line
                    outlined
                    dense
                    hide-details
                    class="profile-list-row-selection"
                    @input="merchantListLengthChange()"
                  ></v-select>
                  <div class="pl-3">
                    {{ t('Rows') }}
                  </div>
                </div>
              </v-col>

              <v-col
                lg="10"
                cols="9"
                class="d-flex justify-end"
              >
                <v-pagination
                  v-model="merchantTablePagination.current_page"
                  total-visible="6"
                  :length="merchantTablePagination.total_page"
                  @input="merchantPageChange()"
                ></v-pagination>
              </v-col>
            </v-row>
          </v-card-text>

          <!-- table -->
          <v-data-table
            :headers="merchantTableColumns"
            :items="merchants"
            :items-per-page="100"
            :loading="loading"
            mobile-breakpoint="0"
            hide-default-footer
            :disable-sort="true"
            class="text-no-wrap"
          >
            <!-- Actions -->
            <template #[`item.actions`]="{item}">
              <div class="d-flex align-center justify-center">
                <!-- Edit -->
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      :to="{
                        name: (can('write', 'ReportActions') ? 'apps-profile-creation-merchant-edit' : 'apps-profile-creation-merchant-req'),
                        params: { id: item.id }
                      }"
                      icon
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon size="18">
                        {{ icons.mdiPencilOutline }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>

                <!-- Void -->
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      icon
                      small
                      v-bind="attrs"
                      v-on="on"
                      @click="voidableRecord = item; isVoidDialogVisible = true"
                    >
                      <v-icon size="18">
                        {{ icons.mdiDeleteOutline }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Void</span>
                </v-tooltip>

                <!-- View 16/17 -->
                <v-tooltip
                  v-if="item.merchant_cert_url !== 'NULL'"
                  bottom
                >
                  <template #activator="{ on, attrs }">
                    <v-btn
                      icon
                      small
                      :href="item.merchant_cert_url"
                      target="_blank"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon size="18">
                        {{ icons.mdiFileDocumentOutline }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>View 16/17</span>
                </v-tooltip>
                <v-tooltip
                  v-else
                  bottom
                >
                  <template #activator="{ on }">
                    <div v-on="on">
                      <v-btn
                        icon
                        small
                        disabled
                      >
                        <v-icon size="18">
                          {{ icons.mdiHelpCircleOutline }}
                        </v-icon>
                      </v-btn>
                    </div>
                  </template>
                  <span>No 16/17 attached</span>
                </v-tooltip>
              </div>
            </template>

            <!-- Record No. -->
            <template #[`item.id`]="{item}">
              MER-{{ item.id }}
            </template>
          </v-data-table>

          <v-card-text class="pt-5">
            <v-row>
              <v-col
                lg="2"
                cols="3"
              >
                <div class="d-flex align-center">
                  <v-select
                    v-model="merchantTablePagination.per"
                    :items="merchantListLengthOptions"
                    single-line
                    outlined
                    dense
                    hide-details
                    class="profile-list-row-selection"
                    @input="merchantListLengthChange()"
                  ></v-select>
                  <div class="pl-3">
                    {{ t('Rows') }}
                  </div>
                </div>
              </v-col>

              <v-col
                lg="10"
                cols="9"
                class="d-flex justify-end"
              >
                <v-pagination
                  v-model="merchantTablePagination.current_page"
                  total-visible="6"
                  :length="merchantTablePagination.total_page"
                  @input="merchantPageChange()"
                ></v-pagination>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>

    <!-- Void dialog -->
    <v-dialog
      v-model="isVoidDialogVisible"
      width="500"
    >
      <v-card>
        <v-card-title class="d-flex align-center mv-4">
          <div
            v-if="!can('write', 'ReportActions')"
            class="pr-1"
          >
            Request
          </div>
          Void {{ voidableRecord.name }}?
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isVoidDialogVisible = false"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text v-if="can('write', 'ReportActions')">
          This record will be voided.
        </v-card-text>

        <v-card-text v-else>
          <v-textarea
            v-model="newComment"
            dense
            outlined
            hide-details="auto"
            placeholder="Leave your comment here (optional)"
            :disabled="loading"
            class="mb-4"
          ></v-textarea>
          This action will create a void request and this record will be voided after approved by auditor.
        </v-card-text>

        <v-card-actions>
          <v-btn
            v-if="can('write', 'ReportActions')"
            color="error"
            block
            :loading="voidLoading"
            :disabled="voidLoading"
            class="mt-3"
            @click="voidableRecord.cert ? voidCorporate() : voidableRecord.ic ? voidBroker() : voidableRecord.merchant_cert ? voidMerchant() : voidLoyalty()"
          >
            Yes, remove
          </v-btn>

          <v-btn
            v-else
            color="error"
            block
            :loading="voidLoading"
            :disabled="voidLoading"
            class="mt-3"
            @click="createRequest()"
          >
            Yes, send request
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Send SMS dialog -->
    <v-dialog
      v-model="isSendSmsDialogVisible"
      max-width="500"
    >
      <v-card v-if="brokerSms">
        <v-card-title class="d-flex align-center py-3">
          {{ t('SMS Send') }}
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            class="ml-5"
            @click="isSendSmsDialogVisible = false"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          {{ t('Send SMS message to contact number') }} <b>673{{ brokerSms.contact_number }}</b> {{ t('to notify their successful registration') }}.
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            block
            :loading="sendLoading"
            :disabled="sendLoading"
            class="white--text"
            @click="sendBrokerMessage"
          >
            <v-icon
              class="me-2"
              left
            >
              {{ icons.mdiMessageProcessingOutline }}
            </v-icon>
            {{ t('Yes, send message') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  mdiPlus,
  mdiCheckCircleOutline,
  mdiAlertCircleOutline,
  mdiDeleteOutline,
  mdiPencilOutline,
  mdiCalendar,
  mdiPrinter,
  mdiMagnify,
  mdiClose,
  mdiFileDocumentOutline,
  mdiHelpCircleOutline,
  mdiContentSave,
  mdiMessageProcessingOutline,
} from '@mdi/js'
import {
  onMounted,
  ref,
  inject,
  computed,
} from '@vue/composition-api'
import { useRouter } from '@core/utils'
import ability from '@/plugins/acl/ability'
import notificationService from '@/services/notification.service'

export default {
  components: {
  },
  setup() {
    // Initializers
    const store = inject('store')
    const snackbarService = inject('snackbarService')
    const t = inject('t')
    const can = inject('can')
    const { router } = useRouter()
    const isAuditor = ability.can('read', 'ReportSummaries')

    // Table Handlers
    const brokerTableColumns = computed(() => (
      [
        { text: t('Actions'), value: 'actions', align: 'center' },
        { text: t('Record No.'), value: 'id', align: 'center' },
        { text: t('Registration Date'), value: 'registration_date' },
        { text: t('Name'), value: 'name' },
        { text: t('IC No.'), value: 'ic_number' },
        { text: t('Contact No.'), value: 'contact_number' },
      ]
    ))

    const corporateTableColumns = computed(() => (
      [
        { text: t('Actions'), value: 'actions', align: 'center' },
        { text: t('Record No.'), value: 'id', align: 'center' },
        { text: t('Registration Date'), value: 'registration_date' },
        { text: t('Name'), value: 'name' },
        { text: t('ROC No.'), value: 'roc_number' },
        { text: t('Contact Person No.'), value: 'contact_person_number' },
      ]
    ))

    const loyaltyTableColumns = computed(() => (
      [
        { text: t('Actions'), value: 'actions', align: 'center' },
        { text: t('Record No.'), value: 'id', align: 'center' },
        { text: t('Status'), value: 'is_expired', align: 'center' },
        { text: t('Registration Date'), value: 'registration_date' },
        { text: t('Loyalty Type'), value: 'loyalty_type' },
        { text: t('Name'), value: 'name' },
        { text: t('IC No.'), value: 'ic_number' },
        { text: t('Contact No.'), value: 'contact_number' },
      ]
    ))

    const merchantTableColumns = computed(() => (
      [
        { text: t('Actions'), value: 'actions', align: 'center' },
        { text: t('Record No.'), value: 'id', align: 'center' },
        { text: t('Registration Date'), value: 'registration_date' },
        { text: t('Name'), value: 'name' },
        { text: t('ROC No.'), value: 'roc_number' },
        { text: t('Contact Person No.'), value: 'contact_person_number' },
      ]
    ))

    // Properties
    // const [dayNow, monthNow, yearNow] = new Date().toLocaleDateString('en-SG').split('/')
    const userData = JSON.parse(localStorage.getItem('userData'))
    const brokers = ref([])
    const corporates = ref([])
    const loyalties = ref([])
    const merchants = ref([])
    const brokerSearchText = ref('')
    const corporateSearchText = ref('')
    const loyaltySearchText = ref('')
    const merchantSearchText = ref('')
    const brokerTablePagination = ref({
      current_page: 1,
      per: 20,
      total_count: 0,
      total_page: 0,
    })
    const corporateTablePagination = ref({
      current_page: 1,
      per: 20,
      total_count: 0,
      total_page: 0,
    })
    const loyaltyTablePagination = ref({
      current_page: 1,
      per: 20,
      total_count: 0,
      total_page: 0,
    })
    const merchantTablePagination = ref({
      current_page: 1,
      per: 20,
      total_count: 0,
      total_page: 0,
    })
    const loading = ref(false)
    const brokerListLengthOptions = ref([20, 50, 100])
    const corporateListLengthOptions = ref([20, 50, 100])
    const loyaltyListLengthOptions = ref([20, 50, 100])
    const merchantListLengthOptions = ref([20, 50, 100])
    const tab = ref(null)
    const isVoidDialogVisible = ref(false)
    const voidableRecord = ref({})
    const voidLoading = ref(false)
    const newComment = ref(null)
    const recordType = ref(null)
    const statusFilter = ref(null)
    const statusOptions = ref(['Active', 'Expired'])
    const brokerSms = ref({})
    const sendLoading = ref(false)
    const isSendSmsDialogVisible = ref(false)

    // Utils
    const formatDate = date => {
      if (!date) return null
      const [year, month, day] = date.split('-')

      return `${day}/${month}/${year}`
    }

    // Methods
    const isLoading = () => {
      loading.value = true
      snackbarService.info('Loading your list. Please hold...')
    }
    const doneLoading = () => {
      loading.value = false
      snackbarService.success('Done loading your list!')
    }
    const fetchBrokers = () => {
      store
        .dispatch('customerStore/fetchBrokers', {
          page: brokerTablePagination.value.current_page,
          per: brokerTablePagination.value.per,
          search_text: brokerSearchText.value,
        })
        .then(response => {
          const { data, pagination } = response.data
          brokers.value = data
          brokers.value.forEach(field => {
            Object.keys(field).forEach(key => {
              if (field[key] === null) field[key] = 'NULL'
            })
          })
          brokerTablePagination.value = pagination
          doneLoading()
        })
        .catch(error => {
          loading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching brokers. Please refresh!')
        })
    }
    const searchBrokers = () => {
      isLoading()
      fetchBrokers()
    }
    const brokerPageChange = () => {
      isLoading()
      fetchBrokers()
    }
    const brokerListLengthChange = () => {
      brokerTablePagination.value.current_page = 1
      isLoading()
      fetchBrokers()
    }
    const voidBroker = () => {
      voidLoading.value = true
      store
        .dispatch('customerStore/voidBroker', { id: voidableRecord.value.id })
        .then(response => {
          snackbarService.success(response.data.message)
          fetchBrokers()
          isVoidDialogVisible.value = false
          voidLoading.value = false
        })
        .catch(error => {
          voidLoading.value = false
          snackbarService.error(error.data.message || 'Something went wrong while voiding broker. Please refresh!')
        })
    }
    const createComment = id => {
      store
        .dispatch('requestStore/createComment', {
          user_id: userData.id,
          approval_request_id: id,
          comment: newComment.value,
        })
        .then(response => {
          snackbarService.success(response.data.message)
          router.push('/apps/requests/list')
        })
        .catch(error => {
          loading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while saving comment. Please refresh!')
        })
    }
    const createRequest = () => {
      if (voidableRecord.value.cert) recordType.value = 'Corporate'
      else if (voidableRecord.value.ic) recordType.value = 'Broker'
      else if (voidableRecord.value.merchant_cert) recordType.value = 'NiagamasMerchant'
      else recordType.value = 'NiagamasLoyalty'
      store
        .dispatch('requestStore/createRequest', {
          record_id: voidableRecord.value.id,
          record_type: recordType.value,
          requested_by_id: userData.id,
          action: 'Void',
        })
        .then(response => {
          if (newComment.value) {
            createComment(response.data.data.id)
          } else {
            snackbarService.success(response.data.message)
            router.push('/apps/requests/list')
          }
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while saving request. Please refresh!')
        })
    }
    const fetchCorporates = () => {
      store
        .dispatch('customerStore/fetchCorporates', {
          page: corporateTablePagination.value.current_page,
          per: corporateTablePagination.value.per,
          search_text: corporateSearchText.value,
        })
        .then(response => {
          const { data, pagination } = response.data
          corporates.value = data
          corporates.value.forEach(field => {
            Object.keys(field).forEach(key => {
              if (field[key] === null) field[key] = 'NULL'
            })
          })
          corporateTablePagination.value = pagination
          doneLoading()
        })
        .catch(error => {
          loading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching corporates. Please refresh!')
        })
    }
    const searchCorporates = () => {
      isLoading()
      fetchCorporates()
    }
    const corporatePageChange = () => {
      isLoading()
      fetchCorporates()
    }
    const corporateListLengthChange = () => {
      corporateTablePagination.value.current_page = 1
      isLoading()
      fetchCorporates()
    }
    const voidCorporate = () => {
      voidLoading.value = true
      store
        .dispatch('customerStore/voidCorporate', { id: voidableRecord.value.id })
        .then(response => {
          snackbarService.success(response.data.message)
          fetchCorporates()
          isVoidDialogVisible.value = false
          voidLoading.value = false
        })
        .catch(error => {
          voidLoading.value = false
          snackbarService.error(error.data.message || 'Something went wrong while voiding broker. Please refresh!')
        })
    }
    const fetchNiagamasLoyalties = () => {
      store
        .dispatch('customerStore/fetchNiagamasLoyalties', {
          page: loyaltyTablePagination.value.current_page,
          per: loyaltyTablePagination.value.per,
          status: statusFilter.value,
          search_text: loyaltySearchText.value,
        })
        .then(response => {
          const { data, pagination } = response.data
          loyalties.value = data
          loyalties.value.forEach(field => {
            Object.keys(field).forEach(key => {
              if (field[key] === null) field[key] = 'NULL'
            })
          })
          loyaltyTablePagination.value = pagination
          doneLoading()
        })
        .catch(error => {
          loading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching niagamas loyalties. Please refresh!')
        })
    }
    const searchLoyalties = () => {
      isLoading()
      fetchNiagamasLoyalties()
    }
    const loyaltyPageChange = () => {
      isLoading()
      fetchNiagamasLoyalties()
    }
    const loyaltyListLengthChange = () => {
      loyaltyTablePagination.value.current_page = 1
      isLoading()
      fetchNiagamasLoyalties()
    }
    const voidLoyalty = () => {
      voidLoading.value = true
      store
        .dispatch('customerStore/voidNiagamasLoyalty', { id: voidableRecord.value.id })
        .then(response => {
          snackbarService.success(response.data.message)
          fetchNiagamasLoyalties()
          isVoidDialogVisible.value = false
          voidLoading.value = false
        })
        .catch(error => {
          voidLoading.value = false
          snackbarService.error(error.data.message || 'Something went wrong while voiding niagamas loyalty. Please refresh!')
        })
    }
    const fetchNiagamasMerchants = () => {
      store
        .dispatch('customerStore/fetchNiagamasMerchants', {
          page: merchantTablePagination.value.current_page,
          per: merchantTablePagination.value.per,
          search_text: merchantSearchText.value,
        })
        .then(response => {
          const { data, pagination } = response.data
          merchants.value = data
          merchants.value.forEach(field => {
            Object.keys(field).forEach(key => {
              if (field[key] === null) field[key] = 'NULL'
            })
          })
          merchantTablePagination.value = pagination
          doneLoading()
        })
        .catch(error => {
          loading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching niagamas merchants. Please refresh!')
        })
    }
    const searchMerchants = () => {
      isLoading()
      fetchNiagamasMerchants()
    }
    const merchantPageChange = () => {
      isLoading()
      fetchNiagamasMerchants()
    }
    const merchantListLengthChange = () => {
      merchantTablePagination.value.current_page = 1
      isLoading()
      fetchNiagamasMerchants()
    }
    const voidMerchant = () => {
      voidLoading.value = true
      store
        .dispatch('customerStore/voidNiagamasMerchant', { id: voidableRecord.value.id })
        .then(response => {
          snackbarService.success(response.data.message)
          fetchNiagamasMerchants()
          isVoidDialogVisible.value = false
          voidLoading.value = false
        })
        .catch(error => {
          voidLoading.value = false
          snackbarService.error(error.data.message || 'Something went wrong while voiding niagamas merchant. Please refresh!')
        })
    }
    const sendBrokerMessage = () => {
      sendLoading.value = true
      store
        .dispatch('customerStore/sendSMS', { id: brokerSms.value.id })
        .then(response => {
          snackbarService.success(response.data.message)
          sendLoading.value = false
          isSendSmsDialogVisible.value = false
        })
        .catch(error => {
          sendLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while sending message. Please refresh!')
        })
    }
    const updateLastSent = () => {
      if (isAuditor) {
        notificationService.subscribeToBroker({
          connected() {
            console.log('Connected to the broker channel')
          },
          received(data) {
            const broker = brokers.value.find(b => b.id === data.id)
            broker[data.attr] = data.value
          },
        })
      }
    }

    onMounted(() => {
      fetchBrokers()
      updateLastSent()
    })

    return {
      t,
      can,
      loading,
      brokers,
      corporates,
      loyalties,
      merchants,
      brokerTablePagination,
      corporateTablePagination,
      loyaltyTablePagination,
      merchantTablePagination,
      brokerTableColumns,
      corporateTableColumns,
      loyaltyTableColumns,
      merchantTableColumns,
      brokerListLengthOptions,
      corporateListLengthOptions,
      loyaltyListLengthOptions,
      merchantListLengthOptions,
      tab,
      fetchBrokers,
      fetchCorporates,
      fetchNiagamasLoyalties,
      fetchNiagamasMerchants,
      brokerPageChange,
      corporatePageChange,
      loyaltyPageChange,
      merchantPageChange,
      brokerListLengthChange,
      corporateListLengthChange,
      loyaltyListLengthChange,
      merchantListLengthChange,
      formatDate,
      isVoidDialogVisible,
      voidableRecord,
      voidLoading,
      voidBroker,
      voidCorporate,
      voidLoyalty,
      voidMerchant,
      createRequest,
      newComment,
      statusFilter,
      statusOptions,
      searchLoyalties,
      searchBrokers,
      searchCorporates,
      searchMerchants,
      brokerSearchText,
      corporateSearchText,
      loyaltySearchText,
      merchantSearchText,
      brokerSms,
      sendLoading,
      isSendSmsDialogVisible,
      sendBrokerMessage,
      isAuditor,

      icons: {
        mdiPlus,
        mdiCheckCircleOutline,
        mdiAlertCircleOutline,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiCalendar,
        mdiPrinter,
        mdiMagnify,
        mdiClose,
        mdiContentSave,
        mdiFileDocumentOutline,
        mdiHelpCircleOutline,
        mdiMessageProcessingOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
  #broker-list, #corporate-list, #loyalty-list, #merchant-list {
    .profile-list-row-selection {
      max-width: 5.3rem;
    }
  }

  #tab-items {
    background-color: transparent !important;
  }

  ::v-deep .theme--dark.v-btn.v-btn--disabled.v-btn--has-bg.loading-color {
    background: #d3d3d3 !important;
    color: #eee !important;
  }

  .dark-divider {
    border-right: 1px solid rgba(231, 227, 252, 0.14) !important;
  }

  .light-divider {
    border-right: 1px solid rgba(94, 86, 105, 0.14);
  }
</style>
